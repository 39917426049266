<template>
  <div>
    <div class="ctn">
      <div class="menu">
        <div @click="$router.push('/tai-khoan/nap-tien/')">
          <img src="@/assets/img/icon/home-btn-addfund.svg" />
          <p>Nạp Tiền</p>
        </div>
        <div @click="$router.push('/tai-khoan/rut-tien/')">
          <img src="@/assets/img/icon/home-btn-cashout.svg" />
          <p>Rút Tiền</p>
        </div>
        <div @click="$router.push('/luat-choi/luat-choi-xsmn/')">
          <img src="@/assets/img/icon/home-btn-guide.svg" />
          <p>Hướng dẫn</p>
        </div>
        <div @click="setModalSupport(true)">
          <img src="@/assets/img/icon/home-btn-support.svg" />
          <p>CSKH</p>
        </div>
      </div>
    </div>
    <CommonModal
      :title="'Chăm sóc khách hàng'"
      :isOpen="isOpenSupport"
      @updateOpen="setModalSupport"
      :isHiddenFooter="true"
    >
      <div>
        Hãy chọn một cách để liên hệ bộ phận chăm sóc khách hàng
      </div>
      <div class="support-method-container">
        <div v-if="isOpenDeepLink">
          <a class="support-item" @click="openDeepLink('https://zalo.me/0964610210')">
            <img src="@/assets/img/icon/home-btn-zalo.svg" />
            <p class="ml-3 text-white font-size-16">Nhắn tin Zalo</p>
          </a>
          <a class="support-item" @click="openDeepLink('https://www.messenger.com/t/www.tailoc68.vn')">
            <img src="@/assets/img/icon/home-btn-fb.svg" />
            <p class="ml-3 text-white font-size-16">Nhắn tin fanpage</p>
          </a>
        </div>
        <div v-else>
          <a class="support-item" href="https://zalo.me/0964610210">
            <img src="@/assets/img/icon/home-btn-zalo.svg" />
            <p class="ml-3 text-white font-size-16">Nhắn tin Zalo</p>
          </a>
          <a class="support-item mt-3" href="https://www.messenger.com/t/www.tailoc68.vn">
            <img src="@/assets/img/icon/home-btn-fb.svg" />
            <p class="ml-3 text-white font-size-16">Nhắn tin fanpage</p>
          </a>
        </div>
        <a class="support-item mt-3" href="tel:0964610210">
          <img src="@/assets/img/icon/home-btn-phone.svg" />
          <p class="ml-3 text-white font-size-16">Gọi điện hỗ trợ</p>
        </a>
        <a class="support-item mt-3" href="mailto:cskh@thantai68.net">
          <img src="@/assets/img/icon/home-btn-email.svg" />
          <p class="ml-3 text-white font-size-16">Email</p>
        </a>
      </div>
    </CommonModal>
  </div>
</template>

<script>
import CommonModal from '@/components/CommonModal';
import { isAndroid } from 'mobile-device-detect';

export default {
  components: {
    CommonModal
  },
  methods: {
    setModalConfirmPlayTrial(newVal) {
      this.$store.commit('setIsOpenModalPlayTrial', newVal);
    },
    setModalSupport(newVal) {
      this.isOpenSupport = newVal;
    }
  },
  computed: {
    isOpenDeepLink() {
      return isAndroid;
    }
  },
  data() {
    return {
      isOpenSupport: false
    };
  }
};
</script>

<style lang="scss">
.ctn {
  padding: 20px 0px 13px 0px;
  .menu {
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 8px 0;
    img {
      margin-bottom: 5px;
    }
    & > div {
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      color: #1e1e1e;
      cursor: pointer;
    }
  }
}

.support-method-container {
  padding: 24px 0;
}

.support-item {
  background: #4189f3;
  border-radius: 3px;
  padding: 14px;
  align-items: center;
  display: flex;
  flex-flow: row;
}

.font-size-16 {
  font-size: 16px;
}
</style>
