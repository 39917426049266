<template>
  <div
    class="listProductIndex container mb-lg-65"
    :class="{
      customGameSession: isGameSession
    }"
    :style="{
      backgroundColor: isGameSession ? customStyleData.gameSessionBackgroundColor : ''
    }"
  >
    <div class="row row-5" v-if="isLoadingProduct">
      <div class="col-lg-3" v-for="(item, index) in numberPlaceholder" :key="index">
        <content-placeholders :rounded="true">
          <content-placeholders-img class="placeholders-product" />
        </content-placeholders>
      </div>
    </div>

    <div v-else>
      <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.HOME_TAB_NAVIGATE">
        <div v-if="loggedIn && !isGameSession">
          <TabNavigate class="col-12 d-lg-none" />
          <div class="account-container c-row d-flex d-lg-none">
            <div class="w-50">
              <div class="d-flex c-row align-items-center">
                <p class="text-white">Tài khoản mua vé</p>
                <img
                  v-if="buyTicketEye"
                  class="ml-2"
                  src="@/assets/img/icon/home-btn-openeye.svg"
                  @click="buyTicketEye = false"
                />
                <img v-else class="ml-2" src="@/assets/img/icon/home-btn-offeye.svg" @click="buyTicketEye = true" />
              </div>
              <p v-if="buyTicketEye" class="my-money">
                {{ formatPrice(depositBalance) }}
              </p>
              <p v-else class="my-money">{{ `*******đ` }}</p>
            </div>
            <div class="w-50">
              <div class="d-flex c-row align-items-center">
                <p class="text-white">Tài khoản thưởng</p>
                <img
                  v-if="rewardEye"
                  class="ml-2"
                  src="@/assets/img/icon/home-btn-openeye.svg"
                  @click="rewardEye = false"
                />
                <img v-else class="ml-2" src="@/assets/img/icon/home-btn-offeye.svg" @click="rewardEye = true" />
              </div>
              <p v-if="rewardEye" class="my-money">
                {{ formatPrice(rewardBalance) }}
              </p>
              <p v-else class="my-money">{{ `*******đ` }}</p>
            </div>
          </div>
        </div>
      </ExcludeFeatureWrapper>
      <div>
        <div class="hide-pc-ui">
          <div class="grid-layout-1">
            <!-- 1 -->
            <div
              v-if="hasData(periods[0])"
              :class="{
                'product-bg-pc-active': hasTicket(periods[0]),
                'product-bg-pc-inactive': !hasTicket(periods[0])
              }"
              class="w-100 p-line-1"
            >
              <div class="d-flex c-row item-container product-container-pc">
                <div
                  v-for="(item, index) in (periods[0] || {}).periods"
                  :key="index"
                  class="w-50px text-center"
                  @click="onOneProductClick(index, periods[0], item)"
                >
                  <img class="item-logo-pc" :src="item.image_url" />
                  <div
                    :class="{
                      'blue-color-pc': item.have_tickets,
                      'disabled-color-pc': !item.have_tickets
                    }"
                    class="font-weight-600 font-size-14"
                  >
                    {{ getShortName(item) }}
                  </div>
                </div>
              </div>
              <div
                :class="{
                  'red-color-pc': isToday(periods[0]),
                  'black-color-pc': hasTicket(periods[0]) && !isToday(periods[0]),
                  'disabled-color-pc': !hasTicket(periods[0]) && !isToday(periods[0])
                }"
                class="datetime-pc text-center font-size-18 font-weight-600"
              >
                {{ getDateTime((periods[0] || {}).lottery_time) }}
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <div
                  :class="{
                    'btn-red': hasTicket(periods[0]),
                    'btn-disabled': !hasTicket(periods[0])
                  }"
                  class="pointer"
                  @click="onProductClick(periods[0])"
                >
                  MUA NGAY
                </div>
              </div>
            </div>

            <!-- 2 -->
            <div
              v-if="hasData(periods[1])"
              :class="{
                'product-bg-pc-active': hasTicket(periods[1]),
                'product-bg-pc-inactive': !hasTicket(periods[1])
              }"
              class="w-100 p-line-1"
            >
              <div class="d-flex c-row item-container product-container-pc">
                <div
                  v-for="(item, index) in (periods[1] || {}).periods"
                  :key="index"
                  class="w-50px text-center"
                  @click="onOneProductClick(index, periods[1], item)"
                >
                  <img class="item-logo-pc" :src="item.image_url" />
                  <div
                    :class="{
                      'blue-color-pc': item.have_tickets,
                      'disabled-color-pc': !item.have_tickets
                    }"
                    class="font-weight-600 font-size-14"
                  >
                    {{ getShortName(item) }}
                  </div>
                </div>
              </div>
              <div
                :class="{
                  'black-color-pc': hasTicket(periods[1]),
                  'disabled-color-pc': !hasTicket(periods[1])
                }"
                class="datetime-pc text-center font-size-18 font-weight-600"
              >
                {{ getDateTime((periods[1] || {}).lottery_time) }}
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <div
                  :class="{
                    'btn-red': hasTicket(periods[1]),
                    'btn-disabled': !hasTicket(periods[1])
                  }"
                  class="pointer"
                  @click="onProductClick(periods[1])"
                >
                  MUA NGAY
                </div>
              </div>
            </div>

            <!-- 3 -->
            <div
              v-if="hasData(periods[2])"
              :class="{
                'product-bg-pc-active': hasTicket(periods[2]),
                'product-bg-pc-inactive': !hasTicket(periods[2])
              }"
              class="w-100 p-line-1"
            >
              <div class="d-flex c-row item-container product-container-pc">
                <div
                  v-for="(item, index) in (periods[2] || {}).periods"
                  :key="index"
                  class="w-50px text-center"
                  @click="onOneProductClick(index, periods[2], item)"
                >
                  <img class="item-logo-pc" :src="item.image_url" />
                  <div
                    :class="{
                      'blue-color-pc': item.have_tickets,
                      'disabled-color-pc': !item.have_tickets
                    }"
                    class="font-weight-600 font-size-14"
                  >
                    {{ getShortName(item) }}
                  </div>
                </div>
              </div>
              <div
                :class="{
                  'black-color-pc': hasTicket(periods[2]),
                  'disabled-color-pc': !hasTicket(periods[2])
                }"
                class="datetime-pc text-center font-size-18 font-weight-600"
              >
                {{ getDateTime((periods[2] || {}).lottery_time) }}
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <div
                  :class="{
                    'btn-red': hasTicket(periods[2]),
                    'btn-disabled': !hasTicket(periods[2])
                  }"
                  class="pointer"
                  @click="onProductClick(periods[2])"
                >
                  MUA NGAY
                </div>
              </div>
            </div>
          </div>
          <div class="grid-layout-2">
            <!-- 4 -->
            <div
              v-if="hasData(periods[3])"
              :class="{
                'product-bg-pc-active': hasTicket(periods[3]),
                'product-bg-pc-inactive': !hasTicket(periods[3])
              }"
              class="w-100 p-line-2"
            >
              <div class="d-flex c-row item-container product-container-pc">
                <div
                  v-for="(item, index) in (periods[3] || {}).periods"
                  :key="index"
                  class="w-50px text-center"
                  @click="onOneProductClick(index, periods[3], item)"
                >
                  <img class="item-logo-pc" :src="item.image_url" />
                  <div
                    :class="{
                      'blue-color-pc': item.have_tickets,
                      'disabled-color-pc': !item.have_tickets
                    }"
                    class="font-weight-400 font-size-12"
                  >
                    {{ getShortName(item) }}
                  </div>
                </div>
              </div>
              <div
                :class="{
                  'black-color-pc': (periods[3] || {}).have_tickets,
                  'disabled-color-pc': !(periods[3] || {}).have_tickets
                }"
                class="datetime-pc text-center font-size-15 font-weight-400"
              >
                {{ getDateTime((periods[3] || {}).lottery_time) }}
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <div
                  :class="{
                    'btn-red': hasTicket(periods[3]),
                    'btn-disabled': !hasTicket(periods[3])
                  }"
                  class="pointer"
                  @click="onProductClick(periods[3])"
                >
                  MUA NGAY
                </div>
              </div>
            </div>

            <!-- 5 -->
            <div
              v-if="hasData(periods[4])"
              :class="{
                'product-bg-pc-active': hasTicket(periods[4]),
                'product-bg-pc-inactive': !hasTicket(periods[4])
              }"
              class="w-100 p-line-2"
            >
              <div class="d-flex c-row item-container product-container-pc">
                <div
                  v-for="(item, index) in (periods[4] || {}).periods"
                  :key="index"
                  class="w-50px text-center"
                  @click="onOneProductClick(index, periods[4], item)"
                >
                  <img class="item-logo-pc" :src="item.image_url" />
                  <div
                    :class="{
                      'blue-color-pc': item.have_tickets,
                      'disabled-color-pc': !item.have_tickets
                    }"
                    class="font-weight-400 font-size-12"
                  >
                    {{ getShortName(item) }}
                  </div>
                </div>
              </div>
              <div
                :class="{
                  'black-color-pc': hasTicket(periods[4]),
                  'disabled-color-pc': !hasTicket(periods[4])
                }"
                class="datetime-pc text-center font-size-15 font-weight-400"
              >
                {{ getDateTime((periods[4] || {}).lottery_time) }}
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <div
                  :class="{
                    'btn-red': hasTicket(periods[4]),
                    'btn-disabled': !hasTicket(periods[4])
                  }"
                  class="pointer"
                  @click="onProductClick(periods[4])"
                >
                  MUA NGAY
                </div>
              </div>
            </div>

            <!-- 6 -->
            <div
              v-if="hasData(periods[5])"
              :class="{
                'product-bg-pc-active': hasTicket(periods[5]),
                'product-bg-pc-inactive': !hasTicket(periods[5])
              }"
              class="w-100 p-line-2"
            >
              <div class="d-flex c-row item-container product-container-pc">
                <div
                  v-for="(item, index) in (periods[5] || {}).periods"
                  :key="index"
                  class="w-50px text-center"
                  @click="onOneProductClick(index, periods[5], item)"
                >
                  <img class="item-logo-pc" :src="item.image_url" />
                  <div
                    :class="{
                      'blue-color-pc': item.have_tickets,
                      'disabled-color-pc': !item.have_tickets
                    }"
                    class="font-weight-400 font-size-12"
                  >
                    {{ getShortName(item) }}
                  </div>
                </div>
              </div>
              <div
                :class="{
                  'black-color-pc': hasTicket(periods[5]),
                  'disabled-color-pc': !hasTicket(periods[5])
                }"
                class="datetime-pc text-center font-size-15 font-weight-400"
              >
                {{ getDateTime((periods[5] || {}).lottery_time) }}
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <div
                  :class="{
                    'btn-red': hasTicket(periods[5]),
                    'btn-disabled': !hasTicket(periods[5])
                  }"
                  class="pointer"
                  @click="onProductClick(periods[5])"
                >
                  MUA NGAY
                </div>
              </div>
            </div>

            <!-- 7 -->
            <div
              v-if="hasData(periods[6])"
              :class="{
                'product-bg-pc-active': hasTicket(periods[6]),
                'product-bg-pc-inactive': !hasTicket(periods[6])
              }"
              class="w-100 p-line-2"
            >
              <div class="d-flex c-row item-container product-container-pc">
                <div
                  v-for="(item, index) in (periods[6] || {}).periods"
                  :key="index"
                  class="w-50px text-center"
                  @click="onOneProductClick(index, periods[6], item)"
                >
                  <img class="item-logo-pc" :src="item.image_url" />
                  <div
                    :class="{
                      'blue-color-pc': item.have_tickets,
                      'disabled-color-pc': !item.have_tickets
                    }"
                    class="font-weight-400 font-size-12"
                  >
                    {{ getShortName(item) }}
                  </div>
                </div>
              </div>
              <div
                :class="{
                  'black-color-pc': hasTicket(periods[6]),
                  'disabled-color-pc': !hasTicket(periods[6])
                }"
                class="datetime-pc text-center font-size-15 font-weight-400"
              >
                {{ getDateTime((periods[6] || {}).lottery_time) }}
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <div
                  :class="{
                    'btn-red': hasTicket(periods[6]),
                    'btn-disabled': !hasTicket(periods[6])
                  }"
                  class="pointer"
                  @click="onProductClick(periods[6])"
                >
                  MUA NGAY
                </div>
              </div>
            </div>
          </div>
          <div class="mt-32px">
            <div class="d-flex c-row justify-content-space-between w-100">
              <div class="xskt">KẾT QUẢ XỔ SỐ KIẾN THIẾT</div>
              <div class="all pointer" @click="viewAll">Xem tất cả</div>
            </div>
            <div class="mt-3">
              <result
                :is="component"
                :pagination="pagination"
                :listResult="customListResult"
                :totalPage="totalPage"
                :rewards="rewards"
                @updatePagination="updatePagination"
                @getGameResult="getGameResult"
                @onResize="onResize"
                :isLoadEnd="isLoadEnd"
                :isLoading="isLoading"
                :isLoadingDesktop="isLoadingDesktop"
                :gameId="gameId"
                :namePage="namePage"
                :getByUser="getByUser"
              ></result>
            </div>
          </div>
        </div>
        <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.PRODUCT_INDEX" class="d-lg-none">
          <div class="p-product">
            <div v-for="(period, periodIndex) in periods" :key="periodIndex">
              <div :class="{ 'mt-3': periodIndex > 0 || !loggedIn }" class="product-item">
                <div
                  :class="{
                    'red-color': isToday(period),
                    'gray-color': hasTicket(period) && !isToday(period),
                    'disabled-color': !hasTicket(period) && !isToday(period)
                  }"
                  class="w-30"
                  @click="onProductClick(period)"
                >
                  <div v-if="isToday(period)" class="today">Hôm nay</div>
                  <div :class="{ 'mt-product': !isToday }" class="dayOfWeek">
                    {{ getDayOfWeek((period || {}).lottery_time) }}
                  </div>
                  <div class="day">{{ formatDate((period || {}).lottery_time, 'DD') }}</div>
                  <div class="dayOfWeek">Thg {{ getMothYear(period || {}) }}</div>
                </div>
                <div class="ml-3 d-flex align-items-center position-relative">
                  <div class="top-circle"></div>
                  <svg width="2" height="69" viewBox="0 0 2 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 0V69" stroke="black" stroke-width="0.5" stroke-dasharray="2 4 6 8" />
                  </svg>
                  <div class="bottom-circle"></div>
                </div>
                <div class="d-flex align-items-center c-row item-container">
                  <div
                    v-for="(item, index) in (period || {}).periods"
                    :key="index"
                    @click="onOneProductClick(index, period, item)"
                  >
                    <img class="item-logo" :src="item.image_url" />
                    <div
                      :class="{
                        'black-color': item.have_tickets,
                        'disabled-color': !item.have_tickets
                      }"
                      class="item-name"
                    >
                      {{ getShortName(item) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ExcludeFeatureWrapper>
      </div>
      <CommonModal
        :title="''"
        :isNoCloseOnEsc="true"
        :isNoCloseOnBackdrop="true"
        :isOpen="openModal"
        @updateOpen="setOpenModal"
        :isHiddenFooter="true"
        :hideHeaderClose="true"
      >
        <div class="mb-3">
          <div class="text-center">
            Theo quy định của BTC về việc phân phối vé số theo đúng khu vực, bạn vui lòng xác nhận khu vực hiện tại của
            bạn.
          </div>
          <div class="gray-gradient mt-3 pointer">
            <div class="font-weight-bold black-color">MIỀN BẮC</div>
            <div class="font-italic gray-text">Coming soon</div>
          </div>
          <div class="gray-gradient mt-3 pointer">
            <div class="font-weight-bold black-color">MIỀN TRUNG</div>
            <div class="font-italic gray-text">Coming soon</div>
          </div>
          <div class="yellow-gradient mt-3 pointer" @click="ondivClick">
            <div class="font-weight-bold">MIỀN NAM</div>
          </div>
        </div>
      </CommonModal>
    </div>
  </div>
</template>

<script>
// import ProductIndex from './ProductIndex';
// import ProductServices from '../api/product';
import TabNavigate from './Home/TabNavigate';
import { mapGetters } from 'vuex';
import { eventTypeMessage } from '../common/global';
import { KENO_TYPE } from '@/common/global';
import CommonModal from '@/components/CommonModal';
import GameAppService from '@/api/game';
import Result from '@/components/Result';
import { getEntityInfoResult } from '@/utils/functions';
import { isMobile } from 'mobile-device-detect';

export default {
  props: {
    isGameSession: {
      type: Boolean,
      default: false
    }
  },
  components: {
    // ProductIndex,
    TabNavigate,
    CommonModal,
    Result,
    TraditionalResult: () => import('@/views/Result/TraditionalResult')
  },
  data() {
    return {
      // listProduct: [],
      context: this,
      numberPlaceholder: undefined,
      activePlayTrial: false,
      buyTicketAmount: 0,
      rewardAmount: 0,
      buyTicketEye: true,
      rewardEye: true,
      openModal: false,
      periods: [],

      component: null,
      totalPage: 0,
      dataTimeDial: '',
      fromTime: '',
      toTime: '',
      day: '',
      rememberTicket: false,
      getByUser: false,
      timerWaiting: null,
      namePage: '',
      gameId: 0,
      bottom: 50,
      dataAgain: {},
      dataAgainMobile: {},
      isLoading: false,
      isLoadingDesktop: false,
      disabledDates: {},
      pagination: {
        currentPage: 0,
        pageSize: 12
      },
      listResult: [],
      rewards: [],
      isLoadEnd: false,
      lastWidth: undefined,
      isMobile: isMobile,
      periodNo: null
    };
  },
  created() {
    const openModal = localStorage.getItem('region');
    if (!openModal) {
      this.openModal = true;
    }
    this.handlerResize();
    this.getPeriodsByGameId();
    window.addEventListener('resize', this.handlerResize);
    this.lastWidth = window.innerWidth;
    this.handlerCallFirstPage();
  },
  computed: {
    ...mapGetters('partner', ['getterExcludeFeature']),
    ...mapGetters(['getterGlobalExcludeFeature']),
    checkHideFeatureBuyGroup() {
      return (
        this.getterExcludeFeature?.includes(this.FEATURE_HANDLER.BUY_GROUP) ||
        this.getterGlobalExcludeFeature?.includes(this.FEATURE_HANDLER.BUY_GROUP) ||
        this.isPaymentIntegration
      );
    },
    isLoadingProduct() {
      return this.$store.getters.getterIsLoadingProduct;
    },
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    },
    listGameHandler() {
      return this.$store.getters.getterListGameHandler;
    },
    customStyleData() {
      return this.$store.getters.getterCustomStyleData;
    },
    getterResultGame() {
      return this.$store.getters.getterResultGameUrl;
    },
    listGameById() {
      return this.$store.getters.getterListGame;
    },
    customListResult() {
      if (this.listGameById?.[this.gameId]?.handler === 'max3dpro') {
        // Thêm giải phụ đặc biệt cho game max3dpro
        return this.listResult.map((item) => {
          const currentListResult = [...item.lottery_result];
          const firstResult = currentListResult.shift();
          const reverseFirstResult = [...firstResult].reverse();

          currentListResult.unshift(reverseFirstResult);
          currentListResult.unshift(firstResult);

          item.lottery_result = currentListResult;
          return item;
        });
      } else {
        return this.listResult;
      }
    },
    isTraditional() {
      return this.gameId === this.$store.getters.getterGameId['xsmn'];
    },
    gameService() {
      return this.isTraditional ? GameAppService.getTraditionalGameResult : GameAppService.getGameResult;
    },
    pageSize() {
      return this.isTraditional ? 7 : 12;
    }
  },
  methods: {
    isToday(period) {
      return this.formatDate(period.lottery_time, 'DD') === this.formatDate(new CustomDate(), 'DD');
    },
    handlerResize() {
      let width = document.body.clientWidth;
      let data = undefined;
      if (width > 1196) {
        data = 8;
      } else if (width > this.getMobileThresshold() && width < 1196) {
        data = 6;
      } else if (width < this.getMobileThresshold()) {
        data = 6;
      }
      this.numberPlaceholder = data;
    },
    isTodayResult(handler) {
      const timerStr = this.getTimerByHandler(handler);
      if (timerStr && !timerStr.includes('ngày')) {
        const time = timerStr.split(':');
        if (time.length === 3) {
          return time[0] < 18;
        }
        return true;
      }
      return false;
    },
    handleClickRoute(item) {
      if (this.isGameSession && this.isLoadByIframe) {
        this.sendMessageToParent(
          eventTypeMessage.GAME_CLICK,
          item.status ? item.url || '/game-session' : '/game-session'
        );
        return;
      }
      window.dataLayer.push({
        event: 'click-choose-game',
        gameName: item.name,
        labelChooseGame: 'Home page'
      });
      if (item.id === this.listGameHandler?.keno?.id) {
        this.$store.commit('setGameKenoType', KENO_TYPE.BASIC);
      }
      this.$router.push(item.status ? item.url || '/' : '/');
      this.runPlayTrial('choose_game', false);
    },
    setOpenModal(newVal) {
      this.openModal = newVal;
    },
    async getPeriodsByGameId() {
      const periodRes = await GameAppService.getPeriodsByGameId(8, { limit: 7 });
      if (periodRes.data.data) {
        this.periods = periodRes.data.data;
      }
    },
    ondivClick() {
      this.openModal = false;
      localStorage.setItem('region', 'true');
    },
    getDayOfWeek(timeStr) {
      if (!timeStr) {
        return '';
      }
      const day = new CustomDate(timeStr).getDay();
      var weekday = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'];
      return weekday[day];
    },
    getMothYear(timeStr) {
      return this.formatDate(timeStr.lottery_time, 'MM') + ' ' + this.formatDate(timeStr.lottery_time, 'YYYY');
    },
    getDateTime(timeStr) {
      return this.getDayOfWeek(timeStr) + ' ngày ' + this.formatDate(timeStr, 'DD/MM/YYYY');
    },
    getShortName(item) {
      switch (item.product_handler) {
        case 'vungtau':
          return 'V.Tàu';
        case 'baclieu':
          return 'B.Liêu';
        case 'bentre':
          return 'B.Tre';
        case 'dongnai':
          return 'Đ.Nai';
        case 'cantho':
          return 'C.Thơ';
        case 'soctrang':
          return 'S.Trăng';
        case 'angiang':
          return 'A.Giang';
        case 'binhthuan':
          return 'B.Thuận';
        case 'tayninh':
          return 'T.Ninh';
        case 'travinh':
          return 'T.Vinh';
        case 'binhduong':
          return 'B.Dương';
        case 'vinhlong':
          return 'V.Long';
        case 'tphcm':
          return 'HCM';
        case 'longan':
          return 'L.An';
        case 'binhphuoc':
          return 'B.Phước';
        case 'haugiang':
          return 'H.Giang';
        case 'tiengiang':
          return 'T.Giang';
        case 'kiengiang':
          return 'K.Giang';
        case 'dalat':
          return 'Đ.Lạt';
        case 'dongthap':
          return 'Đ.Tháp';
        case 'camau':
          return 'C.Mau';
        default:
          return '';
      }
    },
    hasTicket(item) {
      return ((item || {}).periods || []).find((i) => i.have_tickets) ? true : false;
    },
    onProductClick(item) {
      if (this.hasTicket(item)) {
        this.$router.push({ path: '/xo-so/dien-toan-xsmn-8', query: { period_no: item.lottery_time } });
      }
    },
    onOneProductClick(index, period, item) {
      if (item.have_tickets) {
        this.$router.push({
          path: '/xo-so/dien-toan-xsmn-8',
          query: { period_no: period.lottery_time, indexProduct: index }
        });
      }
    },
    onResize() {
      if (this.lastWidth === window.innerWidth) {
        return;
      }
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      this.listResult = [];
      this.dataAgain = {};
      this.dataAgainMobile = {};
      this.isLoadEnd = false;
      if (isMobile) {
        this.pagination = { currentPage: 0, pageSize: this.pageSize };
        this.getGameResult();
      } else {
        this.updatePagination({ currentPage: 1, pageSize: this.pageSize });
      }
    },
    updatePagination(obj) {
      this.pagination = obj;
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      if (!isMobile) {
        this.getGameResultPaging();
      }
    },

    async handlerCallFirstPage() {
      this.isLoading = true;
      let entity = await getEntityInfoResult(
        { path: '/ket-qua/xsmn-8', params: { slug: 'xsmn-8' } },
        this.getterResultGame
      );
      if (entity) {
        this.gameId = entity.entityId;
        this.component = entity.entityName;
        this.namePage = this.getterResultGame.filter((p) => p.id == this.gameId)[0].name || '';
        this.disabledDates = entity.entityWeekday;
        this.dataTimeDial = this.dataTimeDial || entity.entityTimerPicker;
        this.$emit('updateGameId', this.gameId);
      } else {
        return this.$router.push({ name: 'notFound' });
      }
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      if (isMobile) {
        this.getGameResult();
      } else {
        this.getGameResultPaging(1);
      }
    },

    async getGameResultPaging(exactPage) {
      let data = {
        from: this.fromTime,
        to: this.toTime,
        limit: this.pageSize,
        offset: exactPage ? exactPage : this.pagination.currentPage,
        date: this.dataTimeDial,
        getByUser: this.getByUser,
        gameId: this.gameId,
        periodNo: this.periodNo
      };
      if (JSON.stringify(data) == JSON.stringify(this.dataAgain)) {
        this.isLoadingDesktop = false;
        return;
      }

      if (this.timerWaiting) {
        clearTimeout(this.timerWaiting);
      }
      this.timerWaiting = setTimeout(() => {
        this.gameService(this.gameId, { ...data, gameId: undefined })
          .then((resp) => {
            this.listResult = resp.data.data.lottery_result || resp.data.data.periods;
            this.rewards = resp.data.data.reward_data || [];
            this.totalPage = resp.data.data.total;
            if (exactPage) {
              if (
                (resp.data.data.lottery_result || resp.data.data.periods) &&
                (resp.data.data.lottery_result || resp.data.data.periods.length) + this.pageSize * exactPage >=
                  resp.data.data.total
              ) {
                this.isLoadEnd = true;
              } else {
                this.isLoadEnd = false;
              }
            } else {
              if (
                (resp.data.data.lottery_result || resp.data.data.periods) &&
                (resp.data.data.lottery_result?.length || resp.data.data.periods?.length) +
                  this.pageSize * (this.pagination.currentPage + 1) >=
                  resp.data.data.total
              ) {
                this.isLoadEnd = true;
              } else {
                this.isLoadEnd = false;
              }
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.isLoading = false;
            this.isLoadingDesktop = false;
            this.dataAgain = data;
            if (exactPage) this.pagination.currentPage = exactPage;
          });
      }, 300);
    },

    getGameResult() {
      if (this.isLoadEnd) {
        this.isLoading = false;
        this.isLoadingDesktop = false;
        return;
      }
      let data = {
        from: this.fromTime,
        to: this.toTime,
        limit: this.pageSize,
        offset: this.pagination.currentPage + 1,
        date: this.dataTimeDial,
        getByUser: this.getByUser,
        gameId: this.gameId,
        periodNo: this.periodNo
      };
      this.isLoading = true;
      clearTimeout(this.timerWaiting);
      this.timerWaiting = setTimeout(() => {
        this.gameService(this.gameId, { ...data, gameId: undefined })
          .then((resp) => {
            if (resp.data.data.total < data.limit) {
              if (data.offset === 1) {
                this.listResult = resp.data.data.lottery_result || resp.data.data.periods;
                this.rewards = resp.data.data.reward_data || [];
                this.totalPage = resp.data.data.total;
              }
              this.isLoadEnd = true;
              return;
            }
            if (resp.data.data.periods && resp.data.data.periods.length > 0) {
              if (data.offset === 1) {
                this.listResult = resp.data.data.lottery_result || resp.data.data.periods;
                this.rewards = resp.data.data.reward_data || [];
                this.totalPage = resp.data.data.total;
                return;
              }

              this.listResult = this.listResult.concat(resp.data.data.lottery_result || resp.data.data.periods);
              this.rewards = resp.data.data.reward_data || [];
              this.totalPage = resp.data.data.total;
            } else {
              this.isLoadEnd = true;
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.isLoading = false;
            this.isLoadingDesktop = false;
            // this.dataAgainMobile = { ...data };
            this.pagination.currentPage++;
          });
      }, 1000);
    },
    viewAll() {
      this.$router.push('/ket-qua/xsmn-8');
    },
    hasData(item) {
      return item && Object.keys(item).length > 0;
    }
  },
  mounted() {
    if (this.$store.state.playTrial && this.loggedIn) {
      this.runPlayTrial('choose_game', this.$store.state.playTrial);
    }
  },
  watch: {
    '$store.state.playTrial': function(newValue) {
      this.runPlayTrial('choose_game', newValue);
    }
  }
};
</script>
<style lang="scss" scoped>
.customGameSession {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-top: 10px;
  @media (max-width: map-get($grid-breakpoints, 'fix-lg')) {
    padding-bottom: 10px !important;
  }
}

.c-row {
  flex-flow: row;
}

.account-container {
  padding: 0 16px 13px 15px;
  flex-flow: row;
}

.my-money {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
}

.hide-pc-ui {
  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    display: none !important;
  }
}

.product-index {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(32, 32, 32, 0.08), 0px 8px 16px rgba(32, 32, 32, 0.1);
  border-radius: 8px;
  padding: 12px 4px;
  align-items: center;
  display: flex;
  flex-direction: row;
  img {
    width: 48px;
    height: 48px;
    object-fit: contain;
  }
}

.c-col {
  flex-direction: column;
}

.c-row {
  flex-direction: row;
}

.font-size-12 {
  font-size: 12px;
}

.prize {
  color: #d53c38;
  font-size: 14px;
  font-weight: 700;
  word-break: break-all;
}

.blue-box {
  background-color: #2b50d3;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #ffffff;
  font-size: 11px;
  width: 90px;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.p-product {
  padding: 0 15px;
}

.spacing-item {
  width: 14px;
}

.product-item {
  background: #ffffff;
  border-radius: 10px;
  padding: 4px 10px;
  flex-direction: row;
  display: flex;
  font-family: Open Sans;
  text-align: center;
  height: 96px;
}

.item-div {
  object-fit: cover;
  position: absolute;
  top: -1px;
}

.today {
  background: #c80303;
  border-radius: 12px;
  font-style: italic;
  height: 24px;
  color: #ffffff;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
}

.dayOfWeek {
  font-size: 12px;
}

.day {
  font-size: 26px;
  font-style: italic;
  font-weight: 800;
  line-height: 26px;
}

.red-color {
  color: #c80303;
}

.red-color-pc {
  color: #d31904;
  font-weight: 700;
}

.black-color {
  color: #000000;
}

.black-color-pc {
  color: #333333;
}

.gray-color {
  color: #5c5858;
}

.blue-color-pc {
  color: #00509d;
}

.disabled-color {
  color: #ded8d8;
}

.disabled-color-pc {
  color: #777777;
}

.w-30 {
  width: 30%;
}

.top-circle {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -18px;
  background-color: #bf1b14;
  border-radius: 24px;
  left: -10.5px;
}
.bottom-circle {
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: -18px;
  background-color: #bf1b14;
  border-radius: 12px;
  left: -10.5px;
}

.gray-gradient {
  background: linear-gradient(180deg, #eaeaea 0%, #c4c4c4 100%);
  mix-blend-mode: normal;
  border-radius: 10px;
  padding: 15px 0;
  text-align: center;
  height: 70px;
}

.yellow-gradient {
  background: linear-gradient(180deg, #ffcf3e 0%, #ffe188 100%);
  border-radius: 10px;
  padding: 15px 0;
  text-align: center;
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gray-text {
  color: #666666;
}

.black-color {
  color: #000000;
}

.item-container {
  justify-content: space-around;
  flex: auto;
}

.item-logo {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.item-logo-pc {
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.item-name {
  font-weight: 400;
  font-size: 12px;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-600 {
  font-weight: 600;
}

.mt-product {
  margin-top: 14px;
}

.product-bg-pc-active {
  background: #ffe1a6;
  border-radius: 3px;
}

.product-bg-pc-inactive {
  background: #dad8d8;
  border-radius: 3px;
}

.p-line-1 {
  padding: 27px 0 12px 0;
}

.p-line-2 {
  padding: 31px 0 12px 0;
}

.product-container-pc {
  justify-content: space-around;
}

.grid-layout-1 {
  display: grid;
  row-gap: 352px;
  column-gap: 16px;
  grid-template-columns: auto auto auto;
}

.grid-layout-2 {
  display: grid;
  row-gap: 260px;
  column-gap: 15px;
  grid-template-columns: auto auto auto auto;
  margin-top: 25px;
}

.datetime-pc {
  margin-top: 20px;
}

.btn-red {
  background: #d31904;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 10px 16.5px;
  margin-top: 24px;
  width: 115px;
}

.btn-disabled {
  background: #f7f7f7;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  padding: 10px 16.5px;
  margin-top: 24px;
  width: 115px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-18 {
  font-size: 18px;
}

.justify-content-space-between {
  justify-content: space-between;
  align-items: center;
}
.xskt {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
}

.all {
  text-decoration: underline;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}

.mt-32px {
  margin-top: 32px;
}

.w-50px {
  width: 50px;
}
</style>
