<template>
  <EmptyLayout :isBgTransparent="customStyleData.gameSessionBackgroundColor === 'transparent'">
    <list-product-index :isGameSession="true"></list-product-index>
  </EmptyLayout>
</template>
<script>
import ListProductIndex from '../components/ListProductsIndex';

import EmptyLayout from '../layouts/EmptyLayout.vue';
export default {
  components: {
    ListProductIndex,
    EmptyLayout
  },
  computed: {
    isOnlyMobile() {
      return this.$store.getters.getterIsOnlyMobile;
    },
    customStyleData() {
      return this.$store.getters.getterCustomStyleData;
    }
  },
  mounted() {
    document.body.style.backgroundColor = 'transparent';
  }
};
</script>
<style lang="sass" scoped></style>
